import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faImage, faVideo
} from "@fortawesome/free-solid-svg-icons"
import { faSlideshare } from "@fortawesome/free-brands-svg-icons"

import { getTalkTypeIcon } from "../utils/talk-util"
import FeaturedMarker from "./featured-marker"

import {
    talkContainer,
    date,
    left,
    badges,
    badge,
    faBadge,
    locationContainer,
    location,
    event,
    title
} from "./talk-list.module.scss"
import {
    mentionsContainer,
    icon,
    iconOrLogo,
    imageButton
} from "./mention-list.module.scss"


const TalkItem = ({ node }) => {

    const pressLogo = node.logoImage
    const pressLogoImage = getImage(pressLogo)

    return (

        <Link key={node.id} to={node.fields.path} className={"no-underline"}>
            <li className={talkContainer + (node.featured ? " featured-list-item" : "")}>

                <div className={iconOrLogo}>
                    {pressLogoImage && <GatsbyImage image={pressLogoImage}
                                                    alt={node.location + " Logo"}
                                                    placeholder="blurred"/>}
                    {
                        !pressLogoImage &&
                        <div className={icon}>
                            <FontAwesomeIcon icon={getTalkTypeIcon(node.type)} className={"fa-2x fa-fw"}/>
                        </div>
                    }
                </div>

                <div className={locationContainer}>
                    <div className={title}>
                        <FeaturedMarker featured={node.featured}/>
                        {node.title}
                        {node.image &&
                            <a href={node.image.publicURL}
                               target="_blank"
                               rel="noopener noreferrer"
                               className={imageButton}>
                                <FontAwesomeIcon icon={faImage}/>
                            </a>}
                    </div>
                    <div>
                        <span className={location}>
                            {node.location}
                            {node.event ? ": " : ""}
                        </span>
                        <span className={event}>
                            {node.event && <em>{node.event}</em>}
                        </span>
                    </div>
                </div>
                <div className={left}>
                    <div className={date}>
                        {node.date}
                    </div>
                    <div className={badges}>
                        {node.slides && <div className={badge}>
                            <FontAwesomeIcon icon={faSlideshare} className={"fa-fw " + faBadge}/>
                            Slides
                        </div>}
                        {node.video && <div className={badge}>
                            <FontAwesomeIcon icon={faVideo} className={"fa-fw " + faBadge}/>
                            Video
                        </div>}
                    </div>

                </div>

            </li>
        </Link>
    )
}


const TalkList = ({ talks, displayProjects, sortDate }) => {
    if (sortDate) {
        talks.sort((a, b) => {
            return new Date(b.node.date) - new Date(a.node.date)
        })
    }
    // Filter out hidden talks
    talks = talks.filter(({node}) => !node.hidden)
    return (
        <ul className={mentionsContainer}>
            {talks.map(({ node }) => TalkItem({ node, displayProjects }))}
        </ul>
    )
}
TalkList.propTypes = {
    talks: PropTypes.array.isRequired,
    displayProjects: PropTypes.bool
}
export default TalkList

export const query = graphql`
    fragment TalkFragment on TalksJson {
        location
        event
        logo
        url
        type
        title
        projects
        papers
        date
        video
        slides
        featured
        hidden
        fields {
            name
            path
        }
        logoImage {
            id
            base
            childImageSharp {
                gatsbyImageData(
                    width: 58
                    placeholder: BLURRED
                    formats: [AUTO, PNG, WEBP, AVIF]
                )
                largeGatsbyImageData:gatsbyImageData(
                    width: 256
                    placeholder: BLURRED
                    formats: [AUTO, PNG, WEBP, AVIF]
                )
            }
        }
        parent {
            ... on File {
                name
            }
        }
    }
`
